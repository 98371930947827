import React from "react";
import YouTube from "../../components/mdxComponents/YouTube";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import H from "../../components/Headline";


const breadCrumbLevels = {
    Koti: "/",
    "Analytiikkakonsultointi": "/fi/analytiikka-konsultointi/",
    "Tagien Hallinta": "/fi/tagien-hallinta"
  };
  
// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/tag-management"
);

// Schema
const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Mikä on tagien hallintajärjestelmä?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Tagien hallintajärjestelmä on JavaScript-kirjasto analytiikan ja markkinoinnin tagien toteuttamiseen ja konfigurointiin. HTML-tagit, JavaScript-palaset tai seurantapikselit lisäävät ominaisuuksia verkkosivustollesi tai sovellukseesi ja ne voidaan asentaa vain muutamalla klikkauksella. Kun tagi on julkaistu konttiin, koodi luodaan ja lisätään verkkosivun sivulle (DOM). Uutta lähdekoodia ei oteta käyttöön palvelimelle."
        }
      },
      {
      "@type": "Question",
      "name": "Minkä ongelman TMS ratkaisee?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ajan myötä yhä useammat sosiaalisen median alustat ovat ottaneet käyttöön omat taginsa (Linkedin, Snapchat, Twitter, Pinterest, Instagram, jne.) ja verkkosivustot ovat täyttyneet heidän palasillaan. Tämän seurauksena yritysten verkkokehityskustannukset ovat kasvaneet. Tagien hallintajärjestelmät ratkaisevat nämä ongelmat, koska toteutuksia voidaan laajentaa ja siten säästää aikaa, mikä tarkoittaa, että yritykset voivat säästää rahaa."
      }
    }
  ]
}
`;

const TagManagement = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title={`Tagien Hallinta selitettynä (mukana ${currentYear} vertailu)`}
          description="Opi, mitä tagien hallintajärjestelmät tekevät, niiden hyödyt ja miksi ne ovat tulleet niin suosituiksi (mukaan lukien nykyisten TMS-toimittajien vertailu)."
          lang="fi"
          canonical="/fi/tagien-hallinta"
          image="tag-management-hero"
          alternateLangs={alternateLangs}
        />
        <MainContent article>
          <ImgScreenshot
            src="tag-management/tag-management-hero.png"
            alt="TMS-alustojen toimittajien logot vierekkäin tummalla taustalla"
          />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <H as="h1">Tagien Hallinta</H>
          <p className="baseline">
Tagien hallinta nauttii kasvavasta suosiosta ja kasvavasta yhteisöstä. Useimmat nykyaikaiset verkkosivustot eivät enää pärjää ilman tagien hallintajärjestelmää, joten nykyään noin <a href="https://w3techs.com/technologies/overview/tag_manager" target="_blank">30% kaikista verkkosivustoista käyttää tagien hallintajärjestelmää</a>.
</p>
<p>Mutta tämä on melko uusi markkinointiteknologian trendi.</p>
<p>
  Viime vuosina on syntynyt uusi muuntuva tagien hallintaratkaisujen sektori. Vielä tänäkin päivänä markkinoille tulee uusia ratkaisuja, johtuen muuttuvista säännöksistä ja kehittyvästä markkinointiteknologiasta.
</p>
<p className="baseline">
  Hyvä esimerkki kasvavasta kiinnostuksesta on maailmanlaajuinen haku "Google Tag Manager" (GTM) - suosituin tagien hallintajärjestelmä (TMS) <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">94% markkinaosuudella koko internetissä</a>:
</p>
<ImgScreenshot
  src="tag-management/google-tag-manager-growth-of-popularity-past-10-years.png"
  alt="Google Trends -kaavio, joka näyttää kasvavan kiinnostuksen Google Tag Manageriin"
  caption="Hakujen kasvu 'Google Tag Manager' vuodesta 2010 lähtien osoittaa kasvavan kiinnostuksen tagien hallintajärjestelmiin. Ironista kyllä, yleinen termi 'tag management' ei koskaan noussut yhtä suosituksi kuin TMS-alustojen toimittajat."
  className="article-img"
/>
<p className="baseline">Vaikka äärimmäinen kasvu on jatkunut jo jonkin aikaa, tagien hallintajärjestelmien käytön arvioidaan <b>jatkavan kasvuaan</b> vuosittaisella kasvuvauhdilla <a href="https://www.businesswire.com/news/home/20191209005363/en/Global-Tag-Management-System-Market-Analysis-Report" target="_blank">5,7% vuoteen 2024 saakka.</a></p>
<H as="h2">Mikä on tagien hallintajärjestelmä?</H>
<p>
  Tagien hallintajärjestelmä on JavaScript-kirjasto analytiikan ja markkinoinnin tagien toteuttamiseen ja konfigurointiin.
</p>
<p>
  HTML-tagit, JavaScript-palaset tai seurantapikselit lisäävät ominaisuuksia verkkosivustollesi tai sovellukseesi ja ne voidaan asentaa vain muutamalla klikkauksella.
</p>
<H as="h3">Esimerkkejä tageista</H>
<ul>
  <li>Google Analytics -seuranta</li>
  <li>Videoseuranta</li>
  <li>Kohdistuspikselit (Facebook, Google Ads, Linkedin jne.)</li>
  <li>Rekisteröintilomakkeet</li>
</ul>
<p>
  Tagien toteutukset voidaan tehdä graafisen käyttöliittymän (GUI) kautta helpon asennuksen takaamiseksi.
</p>
<p>Kun tagi on julkaistu konttiin, koodi luodaan ja lisätään verkkosivun sivulle (DOM). Uutta lähdekoodia ei oteta käyttöön palvelimelle.</p>
<p>Suosituin TMS on <Link to="/fi/mikä-on-google-tag-manager">Google Tag Manager</Link>. Se sisältää erilaisia tagityyppejä ja kolmansien osapuolien tarjoamia mallipohjia, joten voit toteuttaa tageja vain muutamalla klikkauksella.</p>
<p>Toteutus perustuu kolmeen komponenttiin:</p>
<ul>
  <li><strong>Tagit</strong> (koodi, joka lisätään sivulle)</li>
  <li><strong>Laukaisijat</strong> (säännöt, joissa on ehdot, jotka määrittävät, milloin tagi laukaistaan)</li>
  <li><strong>Muuttujat</strong> (dynaamiset tiedot, joita käytetään tageissa tai laukaisijoissa)</li>
</ul>

<ImgScreenshot
  src="generic/google-tag-manager-overview-tab-en.png"
  alt="Google Tag Manager -näkymä"
  className="article-img"
  caption="Google Tag Managerin yleiskatsaus, jossa vasemmalla paneelissa näkyvät välilehdet tagien, laukaisijoiden ja muuttujien konfigurointia varten."
/>
<p>Konfigurointi suoritetaan yksinkertaisilla lomakkeilla ja syöttökentillä koodauksen sijaan. </p>
<p>Tämä vähentää virheitä ja tekee verkkosivujen tagien toteutuksesta <b>mahdollisen digitaalisten markkinoijien ilman ohjelmointitaustaa.</b></p>

<ImgScreenshot
  src="event-tracking/google-tag-manager-event-trigger-configuration.png"
  alt="Laukaisijan konfigurointi GTM:ssä"
  className="article-img"
  caption="Laukaisijan konfigurointi GTM:ssä. Se määrittää, milloin tagi suoritetaan. Tämä suoritetaan, kun mitä tahansa HTML-elementtiä klikataan, joka vastaa CSS-valitsinta."
/>

<H as="h2">Minkä ongelman TMS ratkaisee?</H>
<p>
  Web 2.0:n ja kehittyvän markkinointiteknologian myötä digitaalisille markkinoijille on avautunut uusia mahdollisuuksia. Ne ovat mahdollistaneet käyttäjien käyttäytymisen analysoinnin segmentointia varten ja tiedon käytön mainoskampanjoissa.
</p>
<p>Jotta voitaisiin toteuttaa tällaisia kohdennettuja kampanjoita, verkkosivustolle oli ensin asetettava useita <b>tageja tai pikseleitä</b>. Tämän jälkeen seurantapikseli voi luokitella kävijät ja heidän käyttäytymisensä uudelleenmarkkinointia varten Facebookissa tai Google Adsissä.</p>
<p>Ajan myötä yhä useammat sosiaalisen median alustat ovat ottaneet käyttöön omat taginsa (Linkedin, Snapchat, Twitter, Pinterest, Instagram jne.) ja verkkosivustot ovat täyttyneet heidän palasillaan.</p>
<p>Ei ollut epätavallista nähdä 20-40 yksittäistä tagia sivun <code>{`<head>`}</code> -osiossa.</p>
<p>Tämän tagijärjestelmän perusta on hyvin konfiguroitu <Link to="/fi/tapahtuman-seuranta">tapahtumien seuranta</Link>. Kaikki asiaankuuluvat painikkeet on varustettava JavaScript-funktioilla. Kun elementtiä klikataan, JavaScript ilmoittaa asiaankuuluvalle markkinointitagille.</p>
<p>Tagit vaativat enimmäkseen samoja käyttäjämetriikoita, mutta jos halusit lisätä toisen ulottuvuuden tai metrin, siitä tuli nopeasti hankala manuaalinen tehtävä. Joten näiden tagien hallinta yksinään tuli entistä suuremmaksi ongelmaksi.</p>
<p>Joten kehittäjien piti käyttää paljon aikaa JavaScript-koodin liittämiseen HTML-elementteihin. Tieto siitä, kuinka koodi pitäisi tarkalleen kirjoittaa tai mitä muuttujia voitaisiin lähettää mukana, tuli uudeksi organisatoriseksi tehtäväksi.</p>
<p>Yritykset, jotka halusivat suorittaa kampanjoita usein, joutuivat asettamaan tapahtumaseurannan kaikilla uusilla laskeutumissivuilla, jotta kampanjaseuranta jatkuisi. Päätagien, parametrien ja muuttujien ylläpito ja päivittäminen tuli lisätehtäväksi.</p>
<p>Mikä tahansa kokeellinen idea uuden vuorovaikutuksen seuraamisesta tuli vaivaksi, jota oli vaikea perustella. Koodimuutoksista tuli liian työvoimavaltaisia.</p>
<p>Lyhyesti sanottuna muutokset ja kokeilu oli vaikeaa ja epäjoustavaa. Mutta konversioseuranta piti ylläpitää kampanjoiden suorituskyvyn mittaamiseksi. Jos sitä ei mitattaisi kunnolla, parantaminen olisi vaikeaa.</p>
<p>Tämän seurauksena yritysten verkkokehityskustannukset kasvoivat. Ongelma voitiin ratkaista vain itse kirjoitetuilla kirjastoilla, mikä valitettavasti johti kehitystiimien ratkaiseman ongelman siiloissa, keksiessään pyörän uudelleen.</p>
<p>Markkinat olivat kypsiä innovaatioille.</p>

<p>Tagien hallintajärjestelmät ratkaisevat nämä ongelmat, koska toteutukset voidaan laajentaa ja siten <b>säästää aikaa</b>, mikä tarkoittaa, että yritykset voivat <b>säästää rahaa</b>.</p>
<p>Ironista kyllä, tagien hallintajärjestelmä toteutetaan myös <Link to="/fi/wiki-analytics/säilö-tunniste">konttitagin</Link> avulla kaikilla sivuilla. Kuitenkin kaikki myöhemmät tagit voidaan lisätä TMS:n kautta.</p>
<p>Tagihallintakoodi täytyy lisätä ja ottaa käyttöön vain kerran alussa ja kaikki seuraavat tagit toteutetaan tagien hallintatyökalun graafisen käyttöliittymän kautta.</p>

<ImgScreenshot
  src="tag-management/matomo-trigger-types.png"
  alt="Luettelo käytettävissä olevista laukaisintyypeistä Matomo Tag Manager -työkalussa"
  className="article-img"
  caption="Laukaisintyyppien tyypit Matomossa. Laukaisimet määrittävät, milloin tietty koodi tulisi suorittaa, ja valittavana on pitkä lista - koodaustaitoja ei tarvita."
/>

<p>Työmäärä vähenee ja menettely on <b>erittäin yksinkertaistettu</b>.</p>
<p>Yksinkertaistetun toteutuksen ansiosta <Link to="/fi/google-tag-manager-konsultti">tagien hallintakonsultit</Link> voivat konfiguroida seurantatagien tietojen keruun. Tämä uusi rooli organisaatiossa keskittyy JavaScript-ohjelmointiin vain verkkoseuranta- ja digitaalisten markkinointipalveluiden osalta.</p>
<p>Jokainen sääntö ja jokainen tietopiste voitaisiin nyt käyttää useissa tageissa ja jopa useilla toimialueilla.</p>
<p>Tagien hallintaportaali tarjoaa monia etuja. Niin monia itse asiassa, että harvoin kuulee valituksia.</p>

<H as="h2">Edut</H>
<p>
  Yksi tagien hallintajärjestelmien suurista eduista on, että tekninen markkinoija voi toteuttaa markkinointitagit ilman riippuvuutta kehittäjästä.
</p>

<p>Graafinen käyttöliittymä tekee siitä helppoa digitaalisen markkinoijan suorittaa perusasetukset, jotka muuten olisivat liian vaikeita raakakoodissa.</p>

<p><b>Esimerkkinä</b> tässä on luettelo markkinointityökaluista ja analytiikkatageista, joita voit asentaa <b>Matomo Tag Managerilla</b>:</p>
<ImgScreenshot
  src="tag-management/matomo-tag-templates.png"
  alt="Luettelo tageista Matomossa"
  className="article-img"
  caption="Tagityyppien luettelo Matomosta - Tagit tulevat ennalta määritetyn mallin mukana, joten toteuttamiseen tarvitsee täyttää vain lomakekentät."
/>
<p>Alla näet luettelon GTM:n käytettävissä olevista <b>tagivaihtoehdoista</b> (<a href="https://support.google.com/tagmanager/answer/6106924?hl=en" target="_blank" noopener>täydellinen luettelo tuetuista tageista</a>). Verrattuna, GTM:ssä on eniten tagimalleja sekä <a href="https://tagmanager.google.com/gallery/#/?page=1" target="_blank" noopener>mukautettuja malleja</a>.</p>
<ImgScreenshot
  src="tag-management/gtm-tag-types.png"
  alt="Luettelo tageista GTM:ssä"
  className="article-img"
  caption="Luettelo tageista GTM:ssä - jouduin lyhentämään tagiluetteloa tilasyistä. Jos katsot mukautettujen mallien alta, löytyy vielä enemmän tagimalleja."
/>

<p className="baseline">Koska tagien käyttöönotto tapahtuu sekunneissa ja markkinointitiimin ei tarvitse enää odottaa koodin käyttöönottoa palvelimella, TMS:n käyttö <b>säästää aikaa, kustannuksia ja vähentää projektinhallinnan yleiskustannuksia</b>.<br />Tämä vahvistetaan Econsultancyn vuonna 2015 laatimassa raportissa, jossa <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">73% markkinoijista sanoi, että TMS:n käyttö auttaa vähentämään kustannuksia</a>.</p>
<p>Tämän joustavuuden ansiosta ketterät työnkulut ovat mahdollisia, mikä mahdollistaa asteittaiset kampanjaoptimoinnit, testit ja kokeilut.</p>
<p>Digitaalisia markkinointikampanjoita voidaan ajaa monissa eri muodoissa kohdentamalla tarkemmin ryhmiä, mikä lisää konversioita ja tarjoaa hyvän perustan oppia, kuinka ryhmät reagoivat mukautettuihin viesteihin.</p>
<p>Yritykset, joilla on jo olemassa tietosiiloja, kuten tietokantoja, CRM-järjestelmiä ja API-rajapintoja, voivat käyttää <Link to="/fi/data-kerros">datalayeria</Link> tehdäkseen tiedot saataville kampanjaseurannan sääntöjä varten.</p>
<p>Toinen aliarvostettu etu ovat käytettävissä olevat asetukset työtiloille, ympäristöille ja käyttäjäoikeuksille, jotka muodostavat hallintaratkaisun tarjotakseen pääsyn virastoille tai ulkopuolisille konsulteille. Pääsy voidaan myöntää sekunneissa, jotta joku voi työskennellä yksittäisen tagin tai koko tagisuunnitelman parissa.</p>

<p>Yhteenvetona, <b>tagien hallinnan edut</b> ovat seuraavat:</p>
<ul>
  <li>Kustannussäästöt alhaisempien toteutuskustannusten ansiosta</li>
  <li>Nopeat toteutukset</li>
  <li>Lisääntynyt joustavuus, koska muutokset ja korjaukset voidaan toteuttaa sekunneissa</li>
  <li>Vahva ja vakaa ulkoisten tietolähteiden integraatio</li>
  <li>Mahdollisuus helposti antaa erilaisia käyttäjäoikeuksia ulkopuolisille kumppaneille</li>
</ul>

<H as="h3">Haitat</H>
<p>On perusteltu huoli siitä, että <b>verkkosivuston latausnopeus hidastuu</b> TMS:ää käytettäessä.</p>
<p>Kuitenkin tämä tapahtuu vain hyvin rajoitetusti, koska tagit latautuvat asynkronisesti.</p>
<p className="baseline">Hyvin konfiguroidulla TMS:llä varustetut verkkosivustot <b>latautuvat yleensä nopeammin</b> kuin jos tagit toteutettaisiin suoraan lähdekoodiin. Econsultancyn vuonna 2015 laatiman raportin mukaan <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">64% TMS:n käyttäjistä näkee nopeamman verkkosivuston suorituskyvyn</a>.</p>
<p>Koska latausnopeuden optimointi jätetään usein julmasti huomiotta ja TMS:n aiheuttama nopeuden vähennys on minimaalinen, on yleensä paljon tehokkaampia tapoja parantaa latausaikaa kuin luopua tagien hallintatyökaluista kokonaan.</p>

<p>Ja kuka sinun mielestäsi olisi kiinnostunut tarpeeksi helpottamaan digitaalista mainontaa ja konversioseurantaa kehittääkseen ratkaisun ilmaiseksi? Sen täytyy olla yritys, joka hyötyy muiden harjoittamasta digitaalisesta mainonnasta 😉.</p>

<H as="h2">Alustojen vertailu</H>

<p>Tässä on vertailu tärkeimmistä alustoista tagien hallintajärjestelmämarkkinoilla:</p>

<table>
  <thead>
    <tr>
      <th>&nbsp;</th>
      <th>Hinta</th>
      <th>Datanoikeudet</th>
      <th>Asiantuntijoiden saatavuus</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><b>GTM (ilmainen)</b></td>
      <td>ilmainen</td>
      <td>ei</td>
      <td>korkea</td>
    </tr>
    <tr>
      <td><b>GTM 360</b></td>
      <td>korkea</td>
      <td>kyllä</td>
      <td>korkea</td>
    </tr>
    <tr>
      <td><b>Adobe Launch</b></td>
      <td>korkea</td>
      <td>kyllä</td>
      <td>keskitaso</td>
    </tr>
    <tr>
      <td><b>Tealium iQ</b></td>
      <td>keskitaso</td>
      <td>kyllä</td>
      <td>matala</td>
    </tr>
    <tr>
      <td><b>Matomo</b></td>
      <td>matala</td>
      <td>kyllä</td>
      <td>matala</td>
    </tr>
    <tr>
      <td><b>Piwik</b></td>
      <td>keskitaso</td>
      <td>kyllä</td>
      <td>matala</td>
    </tr>
  </tbody>
</table>

<p>Yrityksille valinta osuu yleensä premium-alustoihin, kuten <b>Google Tag Manager 360, Adobe Launch</b> (Adobe DTM:n seuraaja) tai <b>Tealium iQ</b>, koska ne täyttävät yritysten tietosuojavaatimukset asiakastietojen osalta.</p>
<p>
  Premium-TMS:n hinta Googlen 360, Adoben tai Tealiumin osalta ei ole tarkasti määriteltävissä, koska ne myydään konfiguroitavissa yrityspaketeissa yhdessä muiden analytiikkaratkaisujen kanssa.
</p>

<p>
  <u>Hinnat ovat siksi vain arvioita ja niitä tulisi pitää karkeana suuntaviivana</u>. Yksittäiset hinnat vaihtelevat tunnetusti.
</p>

<p>Jos haluat nähdä, miltä <b>Tealium Tag Manager</b> näyttää, katso heidän demovideonsa:</p>
<YouTube id="HF1ZiPQYP6M" />
<br />
<p>Samoin tässä on video, jossa näet <b>Adobe Launch</b> -käyttöliittymän ja kuinka sivun lataussääntö määritetään:</p>
<YouTube id="JR2mRfKU18Y" />
<br /><br />
<p>Kuitenkin suosituin tagien hallintatyökalu on <a href="https://tagmanager.google.com/" target="_blank">Google Tag Managerin ilmainen versio</a>.</p>
<H as="h2">Yleiset käyttötapaukset</H>

<p>Datankeruu verkkoseurantaa, digitaalista markkinointia tai data science -tutkimusta varten ovat yleisimpiä tagien hallintajärjestelmien käyttötapauksia.</p>

<H as="h3">Web-analytiikka</H>
<p>Web-analytiikkajärjestelmät, kuten Google Analytics, ovat nopeita asentaa ja helpompia laajentaa useille toimialueille TMS:n avulla. Kaikki myöhemmät lisäykset tai muutokset toteutetaan nopeasti ja vaativat vähemmän resursseja, minkä vuoksi tagien hallinnasta on tullut digitaalisen analytiikan standardi viime vuosina.</p>
<p><b>Konversio-optimointi (CRO)</b> perustuu myös suurelta osin analytiikkatagin mittareihin ja on siksi vahvasti sidoksissa.</p>

<H as="h3">Digitaalinen markkinointi</H>
<p>Mainokset alustoilla kuten Facebook, Twitter, Instagram ja Google Ads ovat suosittuja liikenteen hankintakanavia. Digitaalinen mainonta uudelleenkohdistamisen kautta bannereilla tai Adwordsilla on siksi yleisin käyttötapaus.</p>
<p>Tällainen markkinointiteknologia perustuu tageihin, jotka vaativat datapisteitä yhdeltä tai useammalta digitaaliselta omaisuudelta käyttäjäsegmenttien luomiseksi. Siksi tagien hallinta hallittavalla, laajennettavalla tavalla on edellytys.</p>
<p><b>Kumppanimarkkinointi</b> perustuu konversioiden attribuointiin liikenteen ohjaukselle ja on myös vahvasti riippuvainen evästelogiikasta, joka toteutetaan tagien hallinnan kautta.</p>

<H as="h3">Data Science</H>
<p>AI:n nousun myötä tarve datalle on kasvanut, koska datamallit täytyy kouluttaa massiivisilla datamäärillä.</p>
<p>Mikä tahansa data, jota ei voida kaapia staattisilta sivuilta, kerätään yleensä tagien hallintaratkaisun avulla. Sieltä se lähetetään edelleen tietovarastoon, joka on käytettävissä datamallinnukseen.</p>

<H as="h2">Tagien hallintakonsultointi</H>
<p>Tagien hallintakonsultointipalvelut ilmestyivät samanaikaisesti markkinoiden kasvun kanssa: Lisäpalveluntarjoajat ilmestyivät uusilla ratkaisuilla ja hintapisteillä, jotka piti ottaa huomioon.</p>
<p>Myös <b>EU:n käyttäjätietojen suojaa koskevat lait</b> (GDPR) muuttivat konfiguraatiovaatimuksia ja antoivat yksityisyyteen keskittyville palveluntarjoajille etumatkan.</p>
<p>Tagien hallintakonsultointi keskittyy alustan valintaan, toteutukseen, validointiin ja järjestelmän ylläpitoon.</p>
<p>Tagien hallintakonsultit palkataan yleensä toimistoista, jotka keskittyvät digitaaliseen mainontaan, verkkoseurantaan tai he ovat <Link to="/fi/google-tag-manager-konsultti">freelance-konsultteja</Link>.</p>

<H as="h2">Onko tapauksia, joissa TMS ei ole oikea ratkaisu?</H>
<p><b>Tagien hallintajärjestelmä on oikea valinta useimmille verkkosivustoille ja sovelluksille</b>. Vain verkkosivustot, jotka toimivat aloilla, joilla on erityisiä tietosuojavaatimuksia, tulisi tarkastella perusteellisesti, miten infrastruktuuri on suunniteltu noudattamaan tietosuojalainsäädäntöä.</p>
<p>Kuitenkin tämä ei yleensä ole syy olla käyttämättä TMS:ää, vaan pikemminkin syy valita oikea järjestelmä.</p>
<p>Toinen huomioon otettava seikka voi olla sivuston suorituskyky.</p>
<p>Jos TMS:ää ei ole asetettu oikein, se voi hidastaa verkkosivustoa. Niche-alueilla, joilla mainonnalla on tärkeä rooli, voi olla vaikeuksia hitaiden sivun latausaikojen kanssa. Muutaman sadan millisekunnin säästäminen voisi lisätä arvoa, mutta on kyseenalaista, oikeuttavatko nopeamman latausajan edut korkeammat kustannukset seurantapikselien hallinnassa.</p>
<p>Riippuen talon sisäisten resurssien kyvyistä, on mahdollista ylläpitää verkkosivustoa ja digitaalista markkinointia menestyksekkäästi ilman TMS:ää, mutta nämä ovat yleensä poikkeustapauksia.</p>
<p>Kaikki verkkosivustot, joilla ei ole valtavaa määrää sivuja tai kolmansien osapuolten tagien erityisvaatimuksia, voivat hallita HTML- ja JavaScript-tagejaan TMS:n avulla.</p>
<Helmet>
  <script type="application/ld+json">{FAQs}</script>
</Helmet>
</MainContent>
</React.Fragment>

</Layout>
);

export default TagManagement;


